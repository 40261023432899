import { createStore } from 'vuex';
import blog from './modules/blog';
import project from './modules/project';
import service from './modules/service';
import invest from './modules/invest';

const store = createStore({
  modules: {
    blog,
    project,
    service,
    invest,
  },
  // Add global state, mutations, actions, getters if needed
});

export default store;
