<template>
  <div
    :class="'flex items-center justify-between text-xs md:text-sm w-80 h-12  md:w-96 md:h-16 fixed right-3 bottom-3  bg-keiLightFont dark:bg-dark-keiDarkFont z-50'"
  >
    <span :class="`${notification.type}`" class="h-full w-2"></span>
    <p>
      {{ notification.message }}
    </p>
    <span></span>
  </div>
</template>
<script>
export default {
  name: "NotificationForm",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.success {
  @apply bg-keiSuccess;
}
.error {
  @apply bg-keiError;
}
</style>
