<template>
  <StickyHeader />
  <LoaderComponent v-if="isLoading" />

  <router-view></router-view>
  <FooterSection />
</template>

<script>
import StickyHeader from "@/components/common/StickyHeader";
import FooterSection from "@/components/common/FooterSection";
import LoaderComponent from "@/components/common/LoaderComponent";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    StickyHeader,
    FooterSection,
    LoaderComponent,
  },
  setup() {
    const isLoading = ref(false);
    const router = useRouter();

    router.beforeEach((to, from, next) => {
      isLoading.value = true; // Show loading spinner
      next();
    });

    router.afterEach(() => {
      isLoading.value = false; // Hide loading spinner
    });

    return { isLoading };
  },
};
</script>
