import { createRouter, createWebHistory } from "vue-router";
import store from '../store'

const HomeView = () => import('../views/HomeView.vue');
const ServiceView = () => import('../views/ServiceView.vue');
const ProjectsView = () => import('../views/ProjectsView.vue');
const AboutView = () => import('../views/AboutView.vue');
const BlogView = () => import('../views/BlogView.vue');
const BlogContent = () => import('@/components/blog/BlogContent.vue');
const ContactView = () => import('../views/ContactView.vue');
const InvestmentView = () => import('../views/InvestmentView.vue');
const PropertyList = () => import('../components/investment/PropertyList.vue'); // Adjust the path as needed
const PropertyDetails  = () => import('../components/investment/PropertyDetails.vue'); 
const PrivacyPolice = () => import('@/components/common/PrivacyPolice.vue');
const TermsOfServices = () => import('@/components/common/TermsOfServices.vue');
const CareersPage = () => import('@/components/common/CareersPage.vue');
const SimpleCalculators = () => import('@/components/common/SimpleCalculators.vue');
const NotFound = () => import('@/views/NotFound.vue'); // Err

const routes = [
  {
    path: '/', 
    name: 'HomeView',
    component: HomeView,
    meta: { title: 'Home' },
  },
   {
    path: '/services',
    name: 'ServiceDefaultView',
    component: ServiceView,
    meta: { title: 'Services' },
    beforeEnter: (to, from, next) => {
      let selectedService = sessionStorage.getItem('lastSelectedService') || 'Property Management'; 
      next({ name: 'ServiceSpecificView', params: { serviceName: selectedService } });
    }
  },
  {
    path: '/services/:serviceName',
    name: 'ServiceSpecificView',
    component: ServiceView, 
    meta: { title: 'Services' },
  },
  {
    path: '/projects', 
    name: 'ProjectsView',
    component: ProjectsView,
    meta: { title: 'Projects' },
  },
  {
    path: '/about', 
    name: 'AboutView',
    component: AboutView,
    meta: { title: 'About Us' },
  },
  {
    path: '/blog', 
    name: 'BlogView',
    component: BlogView,
    meta: { title: 'News' },
  },
  {
    path: '/contact', 
    name: 'ContactView',
    component: ContactView,
    meta: { title: 'Contact Us' },
  },
  {
    path: '/blog/:postId',
    name: 'BlogContent',
    component: BlogContent,
    meta: { title: 'Blog Content' },
  },
    {
    path: '/property',
    name: 'PropertyList',
    component: PropertyList,
    
  },
  {
    path: '/property/:id',
    name: 'PropertyDetails',
    component: PropertyDetails,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('invest/fetchInvests');
        const property = store.state.invest.invests.find(p => p.id === Number(to.params.id));
        if (property) {
          next(); // Allow the navigation
        } else {
          next({ name: 'PropertyList' }); // Redirect to property list if the property is not found
        }
      }  
  },
  {
    path: '/invest',
    name: 'InvestmentView',
    component: InvestmentView,
    meta: { title: 'Investment Opportunities' },
  },
  {
    path: '/privacy',
    name: 'PrivacyPolice',
    component: PrivacyPolice,
    meta: { title: 'Privacy Police' },
  },
  {
    path: '/terms',
    name: 'TermsOfServices',
    component: TermsOfServices,
    meta: { title: 'Terms Of Services' },
  },
  {
    path: '/careers',
    name: 'CareersPage',
    component: CareersPage,
    meta: { title: 'Careers' },
  },
  {
    path: '/calculator',
    name: 'SimpleCalculators',
    component: SimpleCalculators,
    meta: { title: 'SimpleCalculators' },
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route for 404
    name: 'NotFound',
    component: NotFound,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    if (savedPosition) {
      return { ...savedPosition, behavior: 'smooth' };
    }
    return { top: 0, behavior: 'smooth' }; // This will smoothly scroll to the top for all route navigations
  }
});
router.beforeEach((to, from, next) => {
  // Update document title based on route meta title
  document.title = to.meta.title || 'Default Title';
  next();
});
export default router