import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles/index.css';
import router from './router';
import store from './store';
import i18n from './directives/i18n'; 
import { registerDirectives } from './directives'; 

const app = createApp(App);

// Register global directives
registerDirectives(app);
// Use plugins
app.use(i18n);
app.use(store);
app.use(router);

app.mount('#app');
