// store/modules/service.js
export default {
  namespaced: true,
  state: {
    services: [],
  },
  mutations: {
    setServices(state, services) {
      state.services = services;
    },
  },
  actions: {
    async fetchServices({ commit }) {
      // console.log('Action fetchService is called');
      try {
        const services = await import('../../assets/files/json/services.json');
        // console.log('Services loaded', services);
        commit('setServices', services.default);
      } catch (error) {
        console.error('Failed to fetch  services:', error);
      }
    },
  },
};
