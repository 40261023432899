export default {
  mounted(el, binding) {
    const animations = {
      fadeIn: { base: '', active: 'fadeIn' },
      reveal: { base: '', active: 'reveal' },
      appear: { base: '', active: 'appear' },
      flyUp: { base: 'flyUp', active: 'flyUp' },
      // Define other animations as needed
    };

    // Default options and merging with binding values
    const options = {
      animationType: 'fadeIn', // Default animation type
      repeat: true, // Repeat animation on each intersection by default
      ...binding.value
    };
    const observerOptions = {
      root: null, // sets the viewport as the container to observe
      rootMargin: '0px', // margin around the root
      threshold: 0.1, // percentage of the target's visibility to trigger the observer
    };
    // Add the base class immediately
    const animation = animations[options.animationType];
    if (animation && animation.base) {
      el.classList.add(animation.base);
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (animation) {
          if (entry.isIntersecting) {
            if (animation.active) {
              el.classList.add(animation.active);
              if (options.animationType === 'flyUp') {
              animateCharacters(el);
          }
            }
          } else {
            if (options.repeat && animation.active) {
              el.classList.remove(animation.active);
            }
          }
        }
      });
    },
    {
      // Optional: observer options
    }, observerOptions);

    observer.observe(el); 
  },
  unmounted(el) {
    if (el._observer) {
      el._observer.disconnect(); // Disconnect the observer
    }
  }
};
function animateCharacters(element) {
  const text = element.innerText;
  element.innerHTML = ''; // Clear existing text

  // Wrap each character in a span and add it back to the container
  for (let i = 0; i < text.length; i++) {
    let span = document.createElement('span');
    span.textContent = text[i];
    span.style.animationDelay = `${i * 0.05}s`; // Adjust the delay as needed
    element.appendChild(span);
  }
}
