<template>
  <div v-if="isModalOpen" class="modal-container">
    <section class="flex items-center justify-center">
      <div class="relative flex items-center justify-center modal-form">
        <ContactUsForm />
        <button class="close-button" @click="closeModal">
          <span class="material-symbols-outlined dark:text-dark-keiLightFont"
            >close</span
          >
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from "vue";
import ContactUsForm from "@/components/contact/ContactUsForm.vue";
// ... other imports ...

export default {
  name: "ModalForm",
  components: { ContactUsForm },
  setup() {
    const isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    return { isModalOpen, openModal, closeModal };
  },
};
</script>
<style scoped>
@keyframes scaleUp {
  from {
    width: 0;
    height: 0;
    opacity: 0;
  }
  to {
    width: 100%; /* Adjust to your desired final width */
    height: 100%; /* Adjust to your desired final height */
    opacity: 1;
  }
}
.modal-form {
  animation: scaleUp 1s ease forwards;
  overflow: hidden; /* To handle intermediate states gracefully */
  /* Set your initial width and height if needed */
  width: 0;
  height: 0;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blurry background */
  z-index: 1000; /* High z-index to overlay on top of everything */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  /* Style your close button */
}
</style>
