<template>
  <section class="bg-keiLightBG dark:bg-dark-keiDarkBG font-montserrat">
    <div
      class="flex flex-col justify-between items-center bg-keiLightFont transition-colors duration-500 text-keiDarkFont dark:bg-dark-keiDarkBG2 dark:text-dark-keiLightFont m-auto px-8 w-80 md:w-[400px] rounded-md overflow-hidden global-shadow dark:dark-global-shadow"
      v-intersect="{ animationType: 'fadeIn', repeat: false }"
    >
      <h2 class="text-h5 font-bold text-center py-8">
        Dont Hesitate to massage us
      </h2>
      <form
        @submit.prevent="submitForm"
        class="flex flex-col items-center gap-3"
      >
        <div class="flex flex-col items-center gap-5">
          <div class="flex gap-2">
            <!-- name -->
            <div class="relative flex items-center">
              <input
                :class="{
                  'border-keiError': formValid.name === false,
                  'border-keiSuccess': formValid.name,
                }"
                @blur="validateField('name')"
                type="text"
                placeholder="Name"
                v-model="formData.name"
                class="input-field"
              />
              <span
                v-if="formValid.name === false"
                class="error-icon material-symbols-outlined"
                @mouseover="showError('name')"
                @mouseleave="hideError"
                :data-title="errorMsg.name"
              >
                error
              </span>
              <span
                v-else-if="formValid.name"
                class="success-icon material-symbols-outlined"
              >
                check_circle
              </span>
            </div>
            <!-- email -->
            <div class="relative flex items-center">
              <input
                :class="{
                  'border-keiError': formValid.name === false,
                  'border-keiSuccess': formValid.name,
                }"
                @blur="validateField('email')"
                type="text"
                placeholder="Email"
                v-model="formData.email"
                class="input-field"
              />
              <span
                v-if="formValid.email === false"
                class="error-icon material-symbols-outlined"
                @mouseover="showError('email')"
                @mouseleave="hideError"
                :data-title="errorMsg.email"
              >
                error
              </span>
              <span
                v-else-if="formValid.email"
                class="success-icon material-symbols-outlined"
              >
                check_circle
              </span>
            </div>
          </div>
          <!-- topic -->
          <div class="relative flex items-center w-full">
            <input
              :class="{
                'border-keiError': formValid.name === false,
                'border-keiSuccess': formValid.name,
              }"
              @blur="validateField('topic')"
              type="text"
              placeholder="topic"
              v-model="formData.topic"
              class="input-field"
            />
            <span
              v-if="formValid.topic === false"
              class="error-icon material-symbols-outlined"
              @mouseover="showError('topic')"
              @mouseleave="hideError"
              :data-title="errorMsg.topic"
            >
              error
            </span>
            <span
              v-else-if="formValid.topic"
              class="success-icon material-symbols-outlined"
            >
              check_circle
            </span>
          </div>
          <!-- textarea -->
          <div class="relative flex items-center w-full">
            <textarea
              :class="{
                'border-keiError': formValid.text === false,
                'border-keiSuccess': formValid.text,
              }"
              @blur="validateField('text')"
              type="text"
              placeholder="text"
              v-model="formData.text"
              class="input-field h-20 flex items-start justify-start"
            >
            <span
              v-if="formValid.text === false"
              class="error-icon material-symbols-outlined"
              @mouseover="showError('text')"
              @mouseleave="hideError"
              :data-title="errorMsg.text"
            >
              error
            </span>
            <span
              v-else-if="formValid.text"
              class="success-icon material-symbols-outlined"
            >
              check_circle
            </span>
            </textarea>
          </div>
          <!-- button -->
          <button type="submit" class="btnS">Submit</button>
        </div>
        <div class="flex py-6 relative">
          <!-- radio -->
          <input
            @blur="validateField('agreeToTerms')"
            type="radio"
            id="agreeToTerms"
            v-model="formData.agreeToTerms"
            class="mr-1 h-8 w-8 md:h-4 md:w-4"
          />

          <label
            :class="{
              'text-keiError': formValid.agreeToTerms === false,
            }"
            class="text-xs"
            @mouseover="showError('agreeToTerms')"
            @mouseleave="hideError"
            :data-title="errorMsg.agreeToTerms"
            for="agreeToTerms"
            >I accept <b>Terms & Conditions</b> for processing personal
            data</label
          >
        </div>
      </form>
      <div v-if="notification.message">
        <NotificationForm :notification="notification" />
      </div>
    </div>
  </section>
</template>
<script>
import { useFormValidation } from "@/composables/useFormValidation";
import { useFormSubmission } from "@/composables/useFormSubmission";
import NotificationForm from "@/components/forms/NotificationForm.vue";

export default {
  name: "ContactUsForm",
  components: { NotificationForm },
  setup() {
    const {
      formData,
      formValid,
      errorMsg,
      isFormValid,
      validateField,
      showError,
      hideError,
    } = useFormValidation();
    const { formStatus, notification, handleSubmit } = useFormSubmission();
    const submitForm = () => {
      handleSubmit(formData, isFormValid);
    };

    // Expose to the template
    return {
      formData,
      formValid,
      errorMsg,
      formStatus,
      notification,
      isFormValid,
      validateField,
      handleSubmit,
      showError,
      hideError,
      submitForm,
    };
  },
};
</script>
