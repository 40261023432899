<template>
  <div class="wall-loader">
    <div class="wall-container">
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <div class="brick dark:dark-brick"></div>
      <hr />
    </div>
  </div>
</template>
<script>
export default {
  name: "LoaderComponent",
};
</script>
<style>
.wall-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}
hr {
  height: 2px;
  widows: 100%;
  background: black;
}
.wall-container {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 75px;
  position: relative;
}

.brick {
  opacity: 1;
  height: 10px;
  border: 1px solid #d9c179;
  background-color: #14322a;
}
.dark-brick {
  border: 1px solid #14322a;
  background-color: #d9c179;
}
.brick:nth-child(1) {
  opacity: 0;
  animation: buildWall1 2s infinite ease-in-out;
  width: 24px;
}
.brick:nth-child(2) {
  opacity: 0;
  animation: buildWall2 2s infinite ease-in-out;
  width: 24px;
}
.brick:nth-child(3) {
  opacity: 0;
  animation: buildWall3 2s infinite ease-in-out;
  width: 16px;
}
.brick:nth-child(4) {
  opacity: 0;
  animation: buildWall6 2s infinite ease-in-out;
  width: 20px;
}
.brick:nth-child(5) {
  opacity: 0;
  animation: buildWall4 2s infinite ease-in-out;
  width: 20px;
}
.brick:nth-child(6) {
  opacity: 0;
  animation: buildWall5 2s infinite ease-in-out;
  width: 24px;
}
.brick:nth-child(7) {
  opacity: 0;
  animation: buildWall8 2s infinite ease-in-out;
  width: 24px;
}
.brick:nth-child(8) {
  opacity: 0;
  animation: buildWall9 2s infinite ease-in-out;
  width: 24px;
}
.brick:nth-child(9) {
  opacity: 0;
  animation: buildWall7 2s infinite ease-in-out;
  width: 16px;
}

@keyframes buildWall1 {
  0%,
  8% {
    opacity: 0;
    transform: translateY(-36px);
  }
  24%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall2 {
  0%,
  16% {
    opacity: 0;
    transform: translateY(-36px);
  }
  32%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall3 {
  0%,
  24% {
    opacity: 0;
    transform: translateY(-36px);
  }
  40%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall4 {
  0%,
  32% {
    opacity: 0;
    transform: translateY(-36px);
  }
  48%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall5 {
  0%,
  40% {
    opacity: 0;
    transform: translateY(-36px);
  }
  56%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall6 {
  0%,
  48% {
    opacity: 0;
    transform: translateY(-36px);
  }
  64%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall7 {
  0%,
  56% {
    opacity: 0;
    transform: translateY(-36px);
  }
  72%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall8 {
  0%,
  64% {
    opacity: 0;
    transform: translateY(-36px);
  }
  80%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes buildWall9 {
  0%,
  72% {
    opacity: 0;
    transform: translateY(-36px);
  }
  88%,
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
