// useFormValidation.js
import { ref, computed } from 'vue';

export function useFormValidation() {
    const formData = ref({
      name: "",
      email: "",
      agreeToTerms: false,
    });

    const formValid = ref({
      name: null,
      email: null,
      agreeToTerms: null,
    });

    const errorMsg = {
      name: "Name is required",
      email: "Invalid email format",
      agreeToTerms: "You must agree to the terms",
    };
   const showError = (field) => {
      const message = errorMsg[field];
      const tooltip = document.querySelector(`[data-title="${message}"]`);
      if (tooltip) {
        tooltip.setAttribute("data-show", "");
      }
    };
    const hideError = () => {
      const tooltips = document.querySelectorAll("[data-title]");
      tooltips.forEach((tooltip) => {
        tooltip.removeAttribute("data-show");
      });
    };
    const isFormValid = computed(() => {
      return (
        formValid.value.name &&
        formValid.value.email &&
        formValid.value.agreeToTerms
      );
    });

      const validateField = (field) => {
      if (field === "name") {
        formValid.value.name = formData.value.name.trim().length > 0;
      } else if (field === "email") {
        formValid.value.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
          formData.value.email
        );
      } else if (field === "agreeToTerms") {
        formValid.value.agreeToTerms = formData.value.agreeToTerms;
      }
    };


    return { formData, formValid, errorMsg, isFormValid, validateField,showError,hideError };
}
