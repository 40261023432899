// store/modules/project.js
export default {
  namespaced: true,
  state: {
    projects: [],
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
    },
  },
  actions: {
    async fetchProjects({ commit }) {
      // console.log('Action fetchProjects is called');
      try {
        const projects = await import('../../assets/files/json/projects.json');
        // console.log('Project loaded', projects);
        commit('setProjects', projects.default);
      } catch (error) {
        console.error('Failed to fetch Project:', error);
      }
    },
  },
};