// store/modules/invest.js
export default {
  namespaced: true,
  state: {
    invests: [],
  },
  mutations: {
    setInvests(state, invests) {
      state.invests = invests;
    },
  },
  actions: {
    async fetchInvests({ commit }) {
      // console.log('Action fetchInvests is called');
      try {
        const invests = await import('../../assets/files/json/invest.json');
        // console.log('invest loaded', invests);
        commit('setInvests', invests.default);
      } catch (error) {
        console.error('Failed to fetch invest:', error);
      }
    },
  },
};