export default {
  mounted(el, binding) {
    const onScroll = () => {
      if (binding.value) {
        binding.value(el);
      }
    };
    window.addEventListener('scroll', onScroll);
    el._onScroll = onScroll;
  },
  unmounted(el) {
    window.removeEventListener('scroll', el._onScroll);
  }
};

