// store/modules/blog.js
export default {
  namespaced: true,
  state: {
    posts: [],
  },
  mutations: {
    setPosts(state, posts) {
      state.posts = posts;
    },
  },
  actions: {
    async fetchPosts({ commit }) {
      // console.log('Action fetchPosts is called');
      try {
        const posts = await import('../../assets/files/json/blog.json');
        // console.log('Blog posts loaded', posts);
        commit('setPosts', posts.default);
      } catch (error) {
        console.error('Failed to fetch blog posts:', error);
      }
    },
  },
};
