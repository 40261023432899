<template>
  <header
    :class="[
      'flex justify-around items-center transition-all  duration-300 z-50 w-full  fixed md:px-5 font-montserrat',
      'bg-keiPrimaryGreen-500',
      { 'md:bg-headerClass-0': !scrolled, 'md:bg-opacity-100': scrolled },
      { ' md:h-16': !scrolled, 'lg:h-12': scrolled },
      headerClass,
    ]"
  >
    <router-link to="/" class="lg:hidden">
      <img :src="logo" alt="KEI-logo" class="w-16" />
    </router-link>
    <div class="flex-1"></div>
    <nav
      :class="{ block: open, hidden: !open }"
      class="hidden md:flex flex-grow flex-shrink justify-center items-center transition duration-300"
    >
      <ul class="flex justify-around gap-5 items-center lg:w-3/4 relative">
        <li class="text-nav">
          <sup
            class="text-dark-keiLightFont rounded-sm font-italic absolute -right-7 top-1"
          >
            New</sup
          >
          <router-link to="/invest">{{ $t("header.invest") }} </router-link>
        </li>
        <li class="text-nav">
          <router-link to="/projects">{{ $t("header.projects") }}</router-link>
        </li>
        <li class="text-nav">
          <router-link to="/about">{{ $t("header.about") }}</router-link>
        </li>
        <li>
          <router-link to="/">
            <img
              :src="logo"
              alt="KEI-logo"
              :class="{
                ' md:hidden lg:block transition-all duration-300  cursor-pointer': true,
                'w-16': !scrolled,
                'w-12': scrolled,
              }"
            />
          </router-link>
        </li>
        <li class="text-nav">
          <router-link to="/services">
            {{ $t("header.services") }}
          </router-link>
        </li>
        <li class="text-nav">
          <router-link to="/blog">{{ $t("header.blog") }}</router-link>
        </li>
        <li class="text-nav">
          <router-link to="/contact"> {{ $t("header.contact") }}</router-link>
        </li>
      </ul>
    </nav>
    <div
      :class="{ hidden: open, flex: !open }"
      class="flex-1 hidden lg:flex justify-end gap-5 w-full"
    >
      <LanguageSwitcher @set-language="setLanguage" />
      <button @click="toggleDarkMode">
        <span
          class="material-symbols-outlined text-keiPrimaryGreen-300 flex items-center transition-transform duration-500 cursor-pointer"
          >{{ darkMode ? "toggle_on" : "toggle_off" }}
        </span>
      </button>

      <button
        class="text-keiCTA text-xs lg:text-sm button-blog-post relative"
        @click="openModal"
      >
        {{ $t("header.book") }}
      </button>
    </div>
    <!-- Burger Menu Icon / Close Icon -->
    <button @click="toggleNav" class="lg:hidden z-50 px-4">
      <div
        :class="{ 'transform rotate-45 translate-y-2 ': open, 'mb-2': !open }"
        class="h-1 w-8 border-t-2 border-b-2 border-keiPrimaryGold-500 duration-300"
      ></div>
      <div
        :class="{ 'opacity-0': open }"
        class="h-1 w-6 border-t-2 border-b-2 border-keiPrimaryGold-500 my-1 duration-300"
      ></div>
      <div
        :class="{
          'transform -rotate-45 -translate-y-2  w-8': open,
          'mt-2 w-4': !open,
        }"
        class="h-1 border-t-2 border-b-2 border-keiPrimaryGold-500 duration-300"
      ></div>
    </button>
    <!-- Full-Screen Overlay Navigation Menu -->
    <div
      :class="{ 'translate-x-0': open, 'translate-x-full': !open }"
      class="fixed inset-y-0 right-0 w-full bg-keiPrimaryGreen-500 shadow-md overflow-hidden z-40 transition-transform duration-300 transform lg:hidden"
    >
      <div class="flex flex-col items-center py-20 h-full">
        <ul>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/">{{ $t("header.home") }}</router-link>
          </li>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/projects">{{
              $t("header.projects")
            }}</router-link>
          </li>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/about">{{ $t("header.about") }}</router-link>
          </li>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/services">
              {{ $t("header.services") }}
            </router-link>
          </li>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/blog">{{ $t("header.blog") }}</router-link>
          </li>
          <li class="text-nav my-4 md:hidden">
            <router-link to="/contact"> {{ $t("header.contact") }}</router-link>
          </li>
          <!-- LanguageSwitcher and CTA button -->
          <li class="text-base md:text-lg lg:text-xl text-white my-4">
            <LanguageSwitcher @set-language="setLanguage" />
          </li>
          <li>
            <button @click="toggleDarkMode">
              <span
                class="material-symbols-outlined text-keiPrimaryGreen-300 flex items-center transition-transform duration-500 cursor-pointer"
                >{{ darkMode ? "toggle_on" : "toggle_off" }}
              </span>
            </button>
          </li>
          <li class="text-xl my-4">
            <button
              class="text-keiPrimaryGold-500 bg-transparent border-none"
              @click="openModal"
            >
              {{ $t("header.book") }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <ModalForm ref="modalFormRef" />
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import LanguageSwitcher from "../forms/LanguageSwitcher.vue";
import ModalForm from "@/components/forms/ModalForm.vue";

export default {
  components: {
    LanguageSwitcher,
    ModalForm,
  },
  name: "StickyHeader",
  setup() {
    const logo = ref(require("@/assets/files/Logo.jpg"));
    const scrolled = ref(false);
    const open = ref(false);
    const darkMode = ref(false);
    const route = useRoute();
    const modalFormRef = ref(null);

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    const openModal = () => {
      if (modalFormRef.value) {
        modalFormRef.value.openModal();
      }
    };
    const handleScroll = () => {
      scrolled.value = window.scrollY > 0;
    };

    const setLanguage = (lang) => {
      this.$i18n.locale = lang;
    };

    const toggleNav = () => {
      open.value = !open.value;
    };

    const toggleDarkMode = () => {
      document.documentElement.classList.toggle("dark");
      darkMode.value = !darkMode.value;
    };

    const headerClass = computed(() => {
      return route.name === "HomeView"
        ? "md:bg-opacity-0"
        : "md:bg-opacity-100";
    });

    // Close the menu when the route changes
    watch(route, () => {
      if (open.value) {
        open.value = false;
      }
    });

    return {
      logo,
      scrolled,
      open,
      darkMode,
      handleScroll,
      setLanguage,
      toggleNav,
      toggleDarkMode,
      headerClass,
      openModal,
      modalFormRef,
    };
  },
};
</script>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}
.button-blog-post::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 20%;
  left: 0;
  background-color: #6b998d; /* Line color */
  transition: width 0.5s ease;
}
:hover.button-blog-post::after {
  width: 100%;
}
:hover.text-nav {
  transition: background 0.5s ease;
  /* background-position-x: 80%; */
  background-size: 100%;
}
</style>
