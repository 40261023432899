<template>
  <section class="h-full py-10 bg-keiPrimaryGreen-500 text-keiLightFont">
    <div
      class="flex flex-wrap justify-between w-[320px] md:w-[640px] lg:w-[800px] xl:w-[1050px] m-auto"
    >
      <div class="mb-2 sm:w-full lg:w-fit">
        <form id="subscribe  " class="w-fit">
          <p class="text-lg font-semibold">Subscribe To Newsletter</p>
          <div
            class="flex items-center border-2 border-keiPrimaryGreen-900 rounded-r-gg my-4"
          >
            <input
              id="subscribeInput"
              type="text"
              class="focus:ring-0 focus:border-keiPrimaryGreen-900 flex-1 p-1 text-sm bg-keiPrimaryGreen-500 placeholder-keiFadeFont"
              placeholder="Email"
            />
            <button
              class="flex items-center justify-center px-3 py-1 bg-keiPrimaryGreen-100 text-keiPrimaryGreen-900 rounded-gg"
            >
              <span class="material-symbols-outlined">east</span>
            </button>
          </div>
        </form>

        <ul
          v-for="contacInfo in contacInfos"
          :key="contacInfo.id"
          class="sm:w-full"
        >
          <li class="flex gap-4 items-center my-3">
            <span class="material-symbols-outlined">
              {{ contacInfo.icon }}
            </span>
            <p class="text-xs py-1 cursor-pointer">
              {{ contacInfo.text }}
            </p>
          </li>
          <hr class="border-t-2 border-keiPrimaryGreen-900" />
        </ul>
      </div>
      <div class="px-2">
        <h4 class="text-h6 md:text-h5 py-4">Servicess</h4>
        <ul>
          <li v-for="service in services" :key="service.id">
            <p
              @click="onServiceSelected(service.name)"
              class="py-1 text-xs md:text-sm cursor-pointer hover:text-keiPrimaryGold-500"
            >
              {{ service.name }}
            </p>
          </li>
        </ul>
      </div>

      <div class="px-2">
        <h4 class="text-h6 md:text-h5 py-4">Navigation</h4>
        <ul>
          <li v-for="navigation in navigations" :key="navigation.id">
            <router-link
              :to="navigation.url"
              class="text-xs md:text-sm py-1 cursor-pointer hover:text-keiPrimaryGold-500"
            >
              {{ navigation.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="px-2">
        <img
          src="@/assets/files/Logo.jpg"
          class="shadow w-16 md:w-28"
          alt="KEI LOGO"
        />
        <ul>
          <li v-for="more in mores" :key="more.id">
            <router-link
              :to="more.url"
              class="text-sm py-1 cursor-pointer hover:text-keiPrimaryGold-500"
            >
              {{ more.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="px-2 pb-2">
        <ul>
          <li v-for="footerCTA in footerCTAs" :key="footerCTA.id">
            <p
              class="text-base py-2 cursor-pointer text-keiPrimaryGold-500 cta"
            >
              {{ footerCTA.title }}
            </p>
          </li>
        </ul>
        
      </div> -->
    </div>
    <hr class="border-t-2 border-keiPrimaryGreen-900 my-2" />
    <div
      class="flex justify-between mt-10 w-[320px] md:w-[640px] lg:w-[800px] xl:w-[1050px] m-auto"
    >
      <ul class="flex gap-3">
        <li v-for="social in socials" :key="social.id">
          <img :src="social.icon" :alt="social.alt" class="w-5" />
        </li>
      </ul>
      <p>copy</p>
    </div>
  </section>
</template>
<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "FooterSection",
  setup() {
    const store = useStore();
    const router = useRouter();

    const services = computed(() => store.state.service.services);

    const fetchServices = async () => {
      await store.dispatch("service/fetchServices");
    };

    // In ServicesSection's setup function
    const onServiceSelected = (serviceName) => {
      console.log("Selected :", serviceName);

      router.push({ name: "ServiceSpecificView", params: { serviceName } });
    };

    onMounted(async () => {
      await fetchServices();
    });

    return {
      services,
      onServiceSelected,
    };
  },

  data() {
    return {
      navigations: [
        { id: 1, title: "Home", url: "/" },
        { id: 2, title: "About Us", url: "/about" },
        { id: 3, title: "Projects", url: "/projects" },
        { id: 4, title: "Services", url: "/services" },
        { id: 5, title: "Contact", url: "/contact" },
        { id: 6, title: "Invest", url: "/invest" },
        { id: 7, title: "News", url: "/blog" },
      ],
      mores: [
        { id: 1, title: "Our Story", url: "/about" },
        { id: 2, title: "Careers", url: "/careers" },
        { id: 3, title: "FAQs", url: "" },
        { id: 4, title: "Privacy Policy", url: "/privacy" },
        { id: 5, title: "Terms of Service", url: "/terms" },
        { id: 6, title: "Calculator", url: "/calculator" },
      ],
      footerCTAs: [
        { id: 1, title: "Schedule a Consultation" },
        { id: 2, title: "View Featured Properties" },
        { id: 3, title: "Get a Free Home Valuation" },
      ],
      contacInfos: [
        {
          id: 1,
          text: "GEORGIA: Tbilisi, 16 Akaki Bakradze Street",
          icon: "location_on",
        },
        {
          id: 2,
          text: "ISRAEL: Holon, Benzion Israeli 15",
          icon: "location_on",
        },
        { id: 3, text: "+33 55 880 555 666", icon: "call" },
        { id: 4, text: "Company@company.com ", icon: "mail" },
      ],
      socials: [
        {
          id: 1,
          alt: "FACEBOOK",
          icon: require("@/assets/files/socials/FACEBOOK.png"),
        },
        {
          id: 2,
          alt: "MESSANGER",
          icon: require("@/assets/files/socials/MESSANGER.png"),
        },
        {
          id: 3,
          alt: "INSTAGRAM",
          icon: require("@/assets/files/socials/INSTAGRAM.png"),
        },
        {
          id: 4,
          alt: "LINKDIN",
          icon: require("@/assets/files/socials/LINKDIN.png"),
        },
        {
          id: 5,
          alt: "SNAPCHAT",
          icon: require("@/assets/files/socials/SNAPCHAT.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.cta::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 20%;
  left: 0;
  background-color: black; /* Line color */
  transition: width 0.5s ease;
}
:hover.cta::after {
  width: 100%;
}
</style>
