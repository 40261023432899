<template>
  <div
    class="relative group"
    @mouseleave="scheduleCloseDropdown"
    @mouseenter="cancelCloseDropdown"
  >
    <!-- Current Language Button -->
    <button @mouseenter="openDropdown" class="flex items-center">
      <span class="material-symbols-outlined text-keiPrimaryGreen-300 text-base"
        >language</span
      >
    </button>

    <!-- Dropdown Menu -->
    <div
      v-show="dropdownOpen"
      class="absolute bg-white shadow-lg border rounded-lg overflow-hidden"
      @mouseenter="cancelCloseDropdown"
      @mouseleave="scheduleCloseDropdown"
    >
      <button
        v-for="lang in otherLanguages"
        :key="lang"
        class="px-4 py-2 text-black hover:bg-gray-100 text-left w-full"
        @click="selectLanguage(lang)"
      >
        {{ lang.toUpperCase() }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n"; // Import useI18n

export default {
  name: "LanguageSwitcher",
  setup(_, { emit }) {
    const { locale } = useI18n(); // Use useI18n to access locale
    const availableLanguages = ["en", "fr"]; // Add more languages as needed
    const dropdownOpen = ref(false);
    let closeDropdownTimeout = null;

    const otherLanguages = computed(() =>
      availableLanguages.filter((lang) => lang !== locale.value)
    );

    const selectLanguage = (lang) => {
      locale.value = lang; // Set the locale
      dropdownOpen.value = false;
      emit("language-changed", lang);
    };

    const closeDropdown = () => {
      dropdownOpen.value = false;
    };

    const openDropdown = () => {
      clearTimeout(closeDropdownTimeout);
      dropdownOpen.value = true;
    };

    const scheduleCloseDropdown = () => {
      closeDropdownTimeout = setTimeout(() => {
        closeDropdown();
      }, 100); // Delay in milliseconds
    };

    const cancelCloseDropdown = () => {
      clearTimeout(closeDropdownTimeout);
    };
    return {
      otherLanguages,
      dropdownOpen,
      selectLanguage,
      openDropdown,
      scheduleCloseDropdown,
      cancelCloseDropdown,
    };
  },
};
</script>
