// useFormSubmission.js
import { ref } from 'vue';

export function useFormSubmission() {
    const formStatus = ref(""); // "success", "error", or ""
    const notification = ref({
      message: "",
      type: "", // 'success' or 'error'
    });

    const handleSubmit = (formData, isFormValid) => {
        if (isFormValid.value) {
            console.log("Form Data:", formData.value);
            // Implement form submission logic here
            formStatus.value = "success";
            notification.value = {
              message: "Form successfully submitted!",
              type: "success",
            };
        } else {
            formStatus.value = "error";
            notification.value = {
              message: "There was a problem sending the form.",
              type: "error",
            };
        }

        // Clear the notification after a delay
        setTimeout(() => {
            notification.value.message = "";
        }, 3000);
    };

    return { formStatus, notification, handleSubmit };
}
``
